













import { prefix } from '@/config/global';
import { Vue, Component, Watch } from 'vue-property-decorator';
import  globalCard  from '@/components/GlobalCard/index.vue';
import  { CardCofing, PerAgePortraitParams, CustomerParams, PassengerFlowTopsParams, PassengerFlowTopsParamsArray, StayHourParams, PerAgePortraitSParamsArray }   from '@/types/store';
import  { activePassengerFlow, lengthStayHour, flowCityDistribution, flowCityDistributionArray, flowAnalysis, frequencyVisits }  from '@/utils/echarts/echarts';
import  { perAgePortrait, customerOriginAnalysis, passengerFlowTops, passengerFlowTopsArray, stayHour, perAgePortraits }  from '@/services/api';
import  { dateArys, getDateAryByStartEnd }  from '@/components/index';
import tdesignFooter from '@/components/GlobalFooter/index.vue';
import spinCard from '@/components/SpinCard/index.vue';
/* eslint-disable @typescript-eslint/naming-convention */

@Component({
  components: {
    globalCard,
    tdesignFooter,
    spinCard,
  },
})

export default class SummaryAnalysis extends Vue {
  prefix: string = prefix;
  cardCofing: Array<CardCofing> = [];
  activePassengerData = {};
  cardType = 0;
  helpSize = '19';
  cardInfoShow = 1;
  cardInfoHide = 0;
  duration = this.$store.state.mall.duration;
  mall_id = this.$store.state.mall.mallId;
  date = this.$store.state.mall.date;

  dateAry: string[] = [];
  // title: 标题
  // titleInfo: 标题提示
  // cardInfoStatus: 是否展示图标问号 1展示，0隐藏
  // helpSize: 提示框大小
  xData = [];
  seriesData = [];

  allData = {
    data1: {},
    data2: {},
    data3: {},
    data4: {},
    data5: {},
    data6: {},
  };
  data1: any = [];
  data2: any = [];
  data3: any = [];
  data4: any = [];
  data5: any = [];
  data6: any = [];
  $beaconAction: any;

  created(): void{
    const option1 = {};
    const option2 = {};
    const option3 = {};
    const option4 = {};
    const option5 = {};
    const option6 = {};
    this.cardCofing = [
      {
        id: 1,
        title: '顾客到访时段占比',
        titleInfo: '顾客入场时间占比情况',
        cardInfoStatus: this.cardInfoShow,
        helpSize: this.helpSize,
        option: option1,
        cardType: 0,
        show: true,
        buriedPoint: 'customer_visit_time_enter',
      },
      {
        id: 2,
        title: '顾客停留时长占比',
        titleInfo: '',
        cardInfoStatus: this.cardInfoHide,
        helpSize: this.helpSize,
        option: option2,
        cardType: 0,
        show: true,
        buriedPoint: 'customer_stay_time_enter',
      },
      {
        id: 3,
        title: '顾客到访频次占比',
        titleInfo: '',
        cardInfoStatus: this.cardInfoHide,
        helpSize: this.helpSize,
        option: option3,
        option2: option6,
        cardType: 1,
        show: true,
        buriedPoint: 'customer_visit_frequency_enter',
      },
      {
        id: 4,
        title: '顾客来源地分析趋势',
        titleInfo: '按照顾客来源地分为：本地顾客、外地顾客。近31天内，不同分类顾客数量和外地顾客占比的变化',
        cardInfoStatus: this.cardInfoShow,
        helpSize: this.helpSize,
        option: option4,
        cardType: 0,
        show: true,
        buriedPoint: 'customer_origin_analysis_enter',
      },
      {
        id: 5,
        title: '外地顾客城市分布占比',
        titleInfo: '',
        cardInfoStatus: this.cardInfoHide,
        helpSize: this.helpSize,
        option: option5,
        cardType: 0,
        show: true,
        buriedPoint: 'customers_distribution_enter',
      },
    ];
  }

  mounted(): void{
    // console.log(this.$beaconAction.onUserAction('222', 111), '---');
  }

  async pageHandler(): Promise<void> {
    this.$stores.global.setDownAble(false); // 数据全部加载完全后才允许下载
    this.cardCofing.forEach((item: CardCofing, index: number) => {
      this.cardCofing[index].show = true;
    });
    // this.date = this.$store.state.mall.dayDate;
    // eslint-disable-next-line prefer-destructuring
    this.date = this.$store.state.mall.rangeDate.split(',')[1];
    this.mall_id = this.$store.state.mall.mallId;
    this.duration = 'd';
    this.dateAry = dateArys(this.date, 31);
    const start = this.$store.state.mall.rangeDate.split(',')[0];
    const dateAry1 = getDateAryByStartEnd(start, this.date);
    const perAgePortraitsParams: PerAgePortraitSParamsArray = {
      mall_id: this.mall_id,
      date_ary: dateAry1,
      type: 2,
    };
    const data1 = await this.getPerAgePortraits(perAgePortraitsParams);
    this.data1 = data1.datas ? data1.datas : [];
    const option1 = data1.option;
    this.cardCofing[0].option = option1;
    this.cardCofing[0].show = false;

    const stayHourParams: StayHourParams = {
      mall_id: this.mall_id,
      time_granularity: 30,
      type: 0,
      date_ary: dateAry1,
      max_mins: 300,
    };

    const data2 = await this.getStayHour(stayHourParams);
    this.data2 = data2.datas ? data2.datas : [];

    const option2 = data2.option;
    this.cardCofing[1].option = option2;
    this.cardCofing[1].show = false;

    const perAgePortraitParams: PerAgePortraitParams = {
      duration: this.duration,
      mall_id: this.mall_id,
      date: this.date,
      type: 3,
      p_type: 1,
      date_ary: dateAry1,
    };

    const data3 = await this.getPerAgePortrait(perAgePortraitParams);
    this.data3 = data3.datas ? data3.datas : [];
    const option3 = data3.option;
    this.cardCofing[2].option = option3;

    perAgePortraitParams.p_type = 2;
    const data6 = await this.getPerAgePortrait(perAgePortraitParams);
    this.data6 = data6.datas ? data6.datas : [];

    const option6 = data6.option;
    this.cardCofing[2].option2 = option6;
    this.cardCofing[2].show = false;

    const customerParams: CustomerParams = {
      duration: this.duration,
      mall_id: this.mall_id,
      date_ary: this.dateAry,
    };
    const data4 = await this.getCustomerAnalysis(customerParams);
    this.data4 = data4.datas ? data4.datas : [];
    const option4 = data4.option;
    this.cardCofing[3].option = option4;
    this.cardCofing[3].show = false;
    const passengerFlowTopsParams: PassengerFlowTopsParamsArray = {
      mall_id: this.mall_id,
      date_ary: dateAry1,
    };
    const data5 = await this.getPassengerFlowTopsArray(passengerFlowTopsParams);
    this.data5 = data5.datas ? data5.datas : [];
    const option5 = data5.option;
    this.cardCofing[4].option = option5;
    this.cardCofing[4].show = false;
    this.$stores.global.setDownAble(true); // 数据全部加载完全后才允许下载
  }

  buriedPointHandler(buriedPoint: string, id: number, params: any = {}): void{
    if (id === 3) return;
    this.$beaconAction.onUserAction(buriedPoint, params);
  }

  processExcelData(): void{
    this.allData.data1 = {
      sheetName: this.cardCofing[0].title,
      sheetData: this.data1,
      sheetHeader: ['顾客到访时段', '占比'],
      sheetMultiHeader: [
        [`数据项:${this.cardCofing[0].title}`],
      ],
      sheetFilter: ['text', 'percent'],
    };
    this.allData.data2 = {
      sheetName: this.cardCofing[1].title,
      sheetData: this.data2,
      sheetHeader: ['顾客停留时长', '占比'],
      sheetMultiHeader: [
        [`数据项:${this.cardCofing[1].title}`],
      ],
      sheetFilter: ['text', 'percent'],
    };
    this.allData.data3 = {
      sheetName: `近7天${this.cardCofing[2].title}`,
      sheetData: this.data3,
      sheetHeader: ['近7天到访次数', '占比'],
      sheetMultiHeader: [
        [`数据项:近7天${this.cardCofing[2].title}`],
      ],
      sheetFilter: ['text', 'percent'],
    };
    this.allData.data4 = {
      sheetName: `近30天${this.cardCofing[2].title}`,
      sheetData: this.data6,
      sheetHeader: ['近30天到访次数', '占比'],
      sheetMultiHeader: [
        [`数据项:近30天${this.cardCofing[2].title}`],
      ],
      sheetFilter: ['text', 'percent'],
    };
    this.allData.data5 = {
      sheetName: this.cardCofing[3].title,
      sheetData: this.data4,
      sheetHeader: ['日期', '本地客流数', '外地客流数', '外地客流占比'],
      sheetMultiHeader: [
        [`数据项:${this.cardCofing[3].title}`],
      ],
      sheetFilter: ['time', 'localtiy_num', 'outside_num', 'outside_ratio'],
    };
    this.allData.data6 = {
      sheetName: this.cardCofing[4].title,
      sheetData: this.data5,
      sheetHeader: ['外地所在城市分布', '占比'],
      sheetMultiHeader: [
        [`数据项:${this.cardCofing[4].title}`],
      ],
      sheetFilter: ['city', 'percent'],
    };
  }

  @Watch('$store.state.mall', { deep: true })
  changeMall(): void{
    this.pageHandler();
  }

  @Watch('$store.state.global.downloadStatus')
  changeExport(): void{
    const status = this.$store.state.global.downloadStatus;
    if (status === true) {
      this.processExcelData();
      console.log(Object.values(this.allData), '11');
      this.$root.$data.event.$emit('getDataS', Object.values(this.allData));
    }
  }

  async getPerAgePortrait(params: PerAgePortraitParams): Promise<any> {
    const dataObj = await perAgePortrait(params);
    const { data, datas } = dataObj;
    const option = frequencyVisits(data.texts, data.percents, params.date);
    return {
      option,
      datas,
    };
  }

  async getPerAgePortraits(params: PerAgePortraitSParamsArray): Promise<any>  {
    const data = await perAgePortraits(params);
    const { texts, percents } = data.data;
    const option = activePassengerFlow(texts, percents);
    return {
      option,
      datas: data.datas,
    };
  }

  async getStayHour(params: StayHourParams): Promise<any> {
    const data = await stayHour(params);
    const { texts, percents, datas } = data;
    const option = lengthStayHour(texts, percents, params.date_ary);
    return {
      option,
      datas,
    };
  }

  async getPassengerFlowTops(params: PassengerFlowTopsParams): Promise<any> {
    const data = await passengerFlowTops(params);
    const { citys, percents, datas } = data;
    const option = flowCityDistribution(citys, percents, params.date);
    return {
      option,
      datas,
    };
  }
  async getPassengerFlowTopsArray(params: PassengerFlowTopsParamsArray): Promise<any> {
    const data = await passengerFlowTopsArray(params);
    const { citys, percents, datas } = data;
    const option = flowCityDistributionArray(citys, percents, params.date_ary);
    return {
      option,
      datas,
    };
  }

  async getCustomerAnalysis(params: CustomerParams): Promise<any> {
    const data = await customerOriginAnalysis(params);
    const { times, localtiyNums, outsideNums, outsideRadios, dataObj, datas } = data;
    const legendData = [{
      name: '本地顾客数',
    }, {
      name: '外地顾客数',
    }, {
      name: '外地顾客占比',
    }];
    const option = flowAnalysis(times, outsideRadios, localtiyNums, outsideNums, legendData, dataObj);
    return {
      option,
      datas,
    };
  }
}
