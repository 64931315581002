




































import { Vue, Component, Prop } from 'vue-property-decorator';
import { prefix } from '@/config/global';
import { CardCofing } from '@/types/store';
import helpCircleFilled from '@/components/HelpCircleFilled/index.vue';

/*
 *图表卡片
 */

@Component({
  components: {
    helpCircleFilled,
  },
})
export default class GlobalCard extends Vue {
  prefix: string = prefix;
  cardCofing2: any = [];
  @Prop({
    type: Object,
    required: true,
    default: '',
  })
  cardCofing!: CardCofing;

  created(): void {
    this.cardCofing2 = [
      {
        id: 1,
        title: '近7天到访次数',
        titleInfo: '近7天内（含当日），顾客累计到访本商场的次数，单个自然日多次到访记录为1次',
        dayType: '7天',
        cardInfoStatus: 1,
        helpSize: '19',
        cardType: 0,
      },
      {
        id: 2,
        title: '近30天到访次数',
        titleInfo: '近30天内（含当日），顾客累计到访本商场的次数，单个自然日多次到访记录为1次',
        dayType: '30天',
        cardInfoStatus: 1,
        helpSize: '19',
        cardType: 0,
      },
    ];
  }

  buriedPointHandler(buriedPoint: string, dayType: string): void{
    const params = {
      menu: dayType,
    };
    this.$emit('buriedPointHandler', buriedPoint, 1, params);
  }

  get cardType(): number {
    return this.cardCofing.cardType;
  }
}
